import axios from "axios";

import Config from "./Config";
import Utils from "./Utils";
import APIHelper from "./APIHelper";

const OnboardingService = {
    /**
     * Retrieves jobs
     * @param {object} settings Related to pagination settings: page, pageSize, sort, search
     * @returns {array} of rows
     */
    jobs(settings) {
        return axios
            .post(Config.API_URL + "onboarding/jobs", settings)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    /**
     * Retrieves jobs from onboarding_xpaths
     * @param {object} settings Related to pagination settings: page, pageSize, sort, search
     * @returns {array} of rows
     */
    xpaths(settings) {
        return axios
            .post(Config.API_URL + "onboarding/xpath", settings)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    setRequested(id, mode) {
        return axios
            .put(Config.API_URL + "onboarding/job/" + id, {
                xpath_requested: mode
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    requestWebsites(brand_id, websites) {
        return axios
            .post(Config.API_URL + "onboarding/new", {
                brand_id,
                websites
            })
            .then(response => APIHelper.returnAttribute(response, 'id'))
            .catch(APIHelper.returnFalse);
    },
    requestXPaths(websites) {
        return axios
            .post(Config.API_URL + "onboarding/xpath/new", {
                websites
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    XPathReport(id, download = true) {
        return axios
            .get(Config.API_URL + "onboarding/job/" + id + "/xpath")
            .then((response) => {
                if (!APIHelper.checkStatus(response)) {
                    return false;
                }

                const data = response.data.data;
                if (!data.manual.length && !data.requested.length) {
                    return -1;
                }

                if (!download) {
                    return data.manual.length ? data.manual : -1;
                }

                let content = "";
                if (data.manual.length) {
                    content = "** Websites that have failed auto-generation phase **\n" + data.manual.join("\n") + "\n\n";
                }
                if (data.requested.length) {
                    content += "** Websites marked to be sent out for manual XPath-mapping **\n" + data.requested.join("\n") + "\n\n";
                }

                Utils.DownloadContent("onboarding-" + id + "-xpath.txt", content);
                return true;
            })
            .catch(APIHelper.returnFalse);
    },
    job(id) {
        return axios
            .get(Config.API_URL + "onboarding/job/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    checkDomain(domain) {
        return axios
            .post(Config.API_URL + "onboarding/domain", { domain })
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    prettifyStatus(row) {
        let color = 'orange',
            hint = '',
            status = '';

        if (row.status === 'pending') {
            status = 'Waiting for autogeneration...';
        } else if (row.status === 'bucketized') {
            status = 'Autogenerated (' + row.bucket + ') after ' + Utils.Seconds2PrettyString(row.delay);
            color = '#1f4503';
        } else if (row.status === 'manual') {
            status = 'Autogeneration failed';//. <span class="request" mId="'+row.marketplace_id+'" onClick="reveal(this)">Request Manual XPath Mapping?</span>';
        } else if (row.status === 'xpathed') {
            status = 'XPath delivered after ' + Utils.Seconds2PrettyString(row.delay);
            color = '#1f4503';
        } else if (row.status === 'requested') {
            status = 'Waiting for manual xpath mapping';
        } else if (row.status === 'fix') {
            status = 'Waiting for manual fix...';
        } else if (row.status === 'fixed') {
            status = 'Manually fixed' + (row.delivered != null && row.delay ? ' after ' + Utils.Seconds2PrettyString(row.delay) : '');
            color = '#1f4503';
        } else if (row.status === 'offline') {
            status = "Offline";
            color = 'red';
        } else if (row.status === 'notpossible') {
            status = "Can't be done.";
            hint = typeof row.reason !== "string" || !row.reason.length ? "Root cause not informed" : row.reason;
            color = 'red';
        } else if (row.status === 'categorized') {
            status = 'Categories synced';
            color = '#1f4503';
        }

        return {
            status,
            color,
            hint
        }
    },
    unidentified() {
        return axios
            .get(Config.API_URL + "onboarding/unidentified")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    markUnidentifiedAsProcessed(id) {
        return axios
            .put(Config.API_URL + "onboarding/unidentified", {id})
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    }
};

export default OnboardingService;
