
class Utils {
    static GetColors() {
        return ["#666699", "#800080", "#FFCC99", "#339966", "#CCFFFF", "#FFFF99", "#808080", "#99CC00", "#000080", "#99CCFF", "#00CCFF", "#FFFF00", "#3366FF", "#008000", "#FF9900", "#003366", "#FF0000", "#CCFFCC", "#0000FF", "#FF00FF", "#33CCCC", "#333333", "#FFCC00", "#00FFFF", "#C0C0C0", "#F2F2F2"];
    }
    static SafeRegex(text) {
        if (typeof text !== "string") {
            text = "" + text;
        }
        return text.trim().replaceAll(/(^|[^\\])(\(|\[|\)|\\|\])/g, '$1\\$2');
    }
    static CleanUpDomain(text) {
        let parts = text.match(/\/\/([^/]+)/);
        if (parts !== null) {
            text = parts[1];
        }
        return text
            .replace(/^www\./, "")
            .trim()
            .toLowerCase();
    }
    static CleanUpSubdomain(text) {
        let parts = text.match(/\/\/([^/]+)/);
        if (parts !== null) {
            text = parts[1];
        }
        text = text.trim();
        return text.substring(-1) === '.' ? text : text + '.';
    }
    static Increase(from, to) {
        return from === 0 ? 0 : ((to * 100) / from - 100).toFixed(2);
    }
    static SimpleDay(datetime) {
        if (typeof datetime !== "string" || datetime.length === 0) {
            return "";
        }
        let dt = new Date(datetime);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[dt.getMonth()] + " " + dt.getDate();
    }
    static Seconds2Time(seconds) {
        seconds = Math.round(seconds);
        let hours, minutes;
        minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        hours = Math.floor(minutes / 60);
        minutes -= hours * 60;
        return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
    }

    static Seconds2PrettyString(seconds) {
        seconds = Math.round(seconds);
        let hours, minutes, days;
        minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        hours = Math.floor(minutes / 60);
        minutes -= hours * 60;
        days = Math.floor(hours / 24);
        hours -= days * 24;
        return (days > 0 ? days + 'd ' : '') + (hours > 0 ? hours + 'h ' : '') + (minutes > 0 ? minutes + 'm ' : '');
    }

    static EqualsObjects(a, b) {
        return typeof a === 'object' && Object.keys(a).length > 0
            ? Object.keys(a).length === Object.keys(b).length
            && Object.keys(a).every(p => Utils.EqualsObjects(a[p], b[p]))
            : a === b;
    }

    static Capitalize(text) {
        if (text === null || typeof text !== "string") {
            return "";
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    };


    static Megabytes(size) {
        return (size / 1024 / 1024).toFixed(2);
    }

    static OrderProxies(list) {
        let proxies = [],
            allProxies = '|' + list.join('|') + '|';
        if (allProxies.match(/tor/)) {
            proxies.push('tor');
        }
        if (allProxies.match(/webproxy/)) {
            proxies.push('webproxy');
        }
        if (allProxies.match(/\|mpp\|/)) {
            proxies.push('mpp');
        }
        if (allProxies.match(/mppsticky/)) {
            proxies.push('mppsticky');
        }
        if (allProxies.match(/hosteons/)) {
            proxies.push('hosteons');
        }
        if (allProxies.match(/\|pubc\|/)) {
            proxies.push('pubc');
        }
        if (allProxies.match(/pubc2/)) {
            proxies.push('pubc2');
        }
        if (allProxies.match(/luminati/)) {
            proxies.push('luminati');
        }
        if (allProxies.match(/dataunblocker/)) {
            proxies.push('dataunblocker');
        }
        if (allProxies.match(/nimble/)) {
            proxies.push('nimble');
        }
        return proxies;
    }

    static DateNow() {
        return (new Date()).toISOString().replace('T', ' ').split('.')[0];
    }

    /**
     * @param {string} datetime Y-m-d
     */
    static MonthAndYear(datetime) {
        let parts = datetime.match(/^(\d{4})-(\d{1,2})/);
        if (parts === null) {
            return "";
        }
        const months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];

        return parts[1] + " - " + months[parts[2] - 1];
    }

    /**
     * Convert something like '%d wordZ' to '1 word' or '2 words'
     * If count is -1, remove the wordZ part if it's surrounded by parenthesis
     */
    static Pluralize(text, count) {
        const regex = /%d (\w+)Z/;
        const parts = text.match(regex);

        if (count === -1 && text.indexOf('(' + parts[0] + ')') !== -1) {
            return text.replace('(' + parts[0] + ')', '').trim();
        }

        if (parts === null) { return text; }
        return text.replace(regex, count === 1 ? '1 ' + parts[1] : count + ' ' + parts[1] + 's');
    }

    /**
     * @returns string Y-m-d
     */
    static DateYesterday() {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        return date.toISOString().substring(0, 10);
    }

    static DownloadContent(filename, content) {
        const url = window.URL.createObjectURL(
            new Blob([content])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            filename
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    static generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
        });
    }

    static UserRoleName(role_id) {
        if (role_id === 2) {
            return "Admin";
        }
        return "Viewer";
    }


    static IsValidEmail(text) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text);
    }

    static getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

    static randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    static isPromise(p) {
        if (typeof p === 'object' && typeof p.then === 'function') {
            return true;
        }
        return false;
    }

    static cleanCSVValue(text) {
        return text.replace(/(\r\n|\n|\r|\\n)/gm, " ").replace(/ {2,}/gm, " ").replace(/\\"/gm, 'inch').replace(/"/gm,
            '');
    }

    // https://miguelmota.com/bytes/validate-domain-regex/
    static isValidDomain(v) {
        if (!v) return false;
        var re = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
        return re.test(v);
    }


    static isMoreRecentThan(dateInQuestion, refDate) {
        if (dateInQuestion == null) {
            return false;
        }
        if (typeof dateInQuestion !== "string" || dateInQuestion.length === 0) {
            return false;
        }
        let d1 = new Date(dateInQuestion),
            d2 = new Date(refDate);

        return d1.getTime() > d2.getTime();
    }


    static UniqueNonEmpty(text, delimiter = '|') {
        if (typeof text !== "string") {
            return [];
        }
        let items = text.split(delimiter),
            unique = [];
        items.forEach((item) => {
            item = item.trim();
            if (item.length && unique.indexOf(item) === - 1) {
                unique.push(item);
            }
        });
        return unique;
    }

    // converts 1234567 into 1,234,567.00"
    // taken from https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
    static FormatNumber(n, withDecimals = true) {
        var re = '\\d(?=(\\d{3})+\\D)',
            num = parseFloat(n).toFixed(Math.max(0, ~~2));
        num = num.replace(new RegExp(re, 'g'), '$&,');
        return withDecimals ? num : num.replace(/\.\d{2}$/, '');
    }

    static PDSystems() {
        return [
            'dd-bdo',
            'dd-upc',
            'dd-bdo+upc',
            'dd-upc+bdo',
            'dd-upc-google-shopping',
            'direct',
            'npd',
            'ng-upc'
        ];
    }

    /**
     * Should return as many decimals as needed to represent the value as non-zero
     */
    static AsManyDecimalsAsNeeded(value, max = 10) {
        let i = 2,
            formatted,
            valid;

        value *= 1;

        if (value % 1 === 0) {
            return value.toFixed(2);
        }

        do {
            formatted = value.toFixed(i++);
            let parts = formatted.split('.');
            valid = parts.length > 1 && parts[1].match(/[1-9]/);
        } while (!valid && i <= max);

        return valid ? formatted : value.toFixed(max - 1) + '1';
    }

    // taken from https://stackoverflow.com/questions/1293147/how-to-parse-csv-data
    static parseCSV(str) {
        const arr = [];
        let quote = false;  // 'true' means we're inside a quoted field

        // Iterate over each character, keep track of current row and column (of the returned array)
        for (let row = 0, col = 0, c = 0; c < str.length; c++) {
            let cc = str[c], nc = str[c + 1];        // Current character, next character
            arr[row] = arr[row] || [];             // Create a new row if necessary
            arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary

            // If the current character is a quotation mark, and we're inside a
            // quoted field, and the next character is also a quotation mark,
            // add a quotation mark to the current column and skip the next character
            if (cc === '"' && quote && nc === '"') { arr[row][col] += cc; ++c; continue; }

            // If it's just one quotation mark, begin/end quoted field
            if (cc === '"') { quote = !quote; continue; }

            // If it's a comma and we're not in a quoted field, move on to the next column
            if (cc === ',' && !quote) { ++col; continue; }

            // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
            // and move on to the next row and move to column 0 of that new row
            if (cc === '\r' && nc === '\n' && !quote) { ++row; col = 0; ++c; continue; }

            // If it's a newline (LF or CR) and we're not in a quoted field,
            // move on to the next row and move to column 0 of that new row
            if (cc === '\n' && !quote) { ++row; col = 0; continue; }
            if (cc === '\r' && !quote) { ++row; col = 0; continue; }

            // Otherwise, append the current character to the current column
            arr[row][col] += cc;
        }
        return arr;
    }
}

export default Utils;
