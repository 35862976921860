import React, { createRef } from "react";
import PropTypes from "prop-types";
import ProductService from "../services/ProductService";
import Settings from "../services/SettingsService";
import Currencies from "../components/Currencies";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "../../node_modules/currency-flags/dist/currency-flags.min.css";
import "./ProductSearch.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Form, Row } from "react-bootstrap";

/**
 * Typeahead component for searching for Products
 * @component
 */
class ProductSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            includeArchivedProducts: typeof props.includeArchivedProducts === "boolean" ? props.includeArchivedProducts : Settings.Get("products-search-include-archived-products", true),
            includeDisabledBrands: typeof props.includeDisabledBrands === "boolean" ? props.includeDisabledBrands : Settings.Get("products-search-include-disabled-brands", true),
            showOptions: typeof props.includeArchivedProducts !== "boolean" && typeof props.includeDisabledBrands !== "boolean"
        }
        this.ref = createRef();
        this.changeSettings = this.changeSettings.bind(this);
    }

    changeSettings = (evt) => {
        const mode = evt.target.getAttribute("setting"),
            checked = evt.target.checked;
        if (mode === "archived-products") {
            Settings.Set("products-search-include-archived-products", checked);
            this.setState({ includeArchivedProducts: checked });
        } else if (mode === "disabled-brands") {
            Settings.Set("products-search-include-disabled-brands", checked);
            this.setState({ includeDisabledBrands: checked });
        }
    }

    handleSearch = (query) => {
        this.setState({
            isLoading: true,
        });
        ProductService.typeahead(query, {
            "archived-products": this.state.includeArchivedProducts,
            "disabled-brands": this.state.includeDisabledBrands,
            "brands": this.props.brands,
            "manufacturers": this.props.manufacturers
        }).then((options) => {
            if (options === null) {
                // this request was canceled due to a change in the typeahead component
                return;
            }

            let list = [];
            options.forEach((row) => {
                if (typeof this.props.skip === "undefined" || this.props.skip.indexOf(row.id) === -1) {
                    list.push(row);
                }
            });

            this.setState({
                options: list,
                isLoading: false,
            });
        });
    };

    clear = () => {
        this.ref.current.clear();
    }

    render() {
        const filterBy = () => true;

        return <React.Fragment>
            <AsyncTypeahead
                {...this.state}
                disabled={this.props.disabled}
                ref={this.ref}
                filterBy={filterBy}
                labelKey="identifier"
                id="product-search"
                maxResults={10}
                minLength={2}
                onSearch={this.handleSearch}
                onChange={this.props.onChange}
                defaultSelected={this.props.defaultSelected}
                placeholder={this.props.placeholder}
                renderMenuItemChildren={(option) => {
                    const isTracked = (<FontAwesomeIcon
                        style={{
                            color: "#1f4503",
                        }}
                        icon={faCheck}
                    />),
                        isNotTracked = (
                            <FontAwesomeIcon
                                style={{ color: "red" }}
                                icon={faTimes}
                            />
                        ),
                        isArchived = (<FontAwesomeIcon
                            style={{
                                color: "red",
                            }}
                            icon={faCheck}
                        />),
                        isNotArchived = (
                            <FontAwesomeIcon
                                style={{ color: "#1f4503" }}
                                icon={faTimes}
                            />
                        ),
                        upc = typeof option.upc === "string" && option.upc !== option.identifier ? option.upc : null;


                    return (
                        <div
                            key={`pid-${option.id}`}
                            className="product"
                        >
                            <div className="thumb">
                                <img src={option.image} alt={option.identifier} onError={(e) => {
                                    e.target.parentNode.className += " failed";
                                    e.target.remove()
                                }} />
                            </div>
                            <div className="data">
                                <div>
                                    <span className="colHead">id:</span>
                                    <span className="colValue">{option.id}</span>
                                    <span className="colHead">identifier:</span>
                                    <span className="colValue">{option.identifier}</span>
                                    {upc !== null && (
                                        <React.Fragment>
                                            <span className="colHead">upc:</span>
                                            <span className="colValue">{upc}</span>
                                        </React.Fragment>
                                    )}
                                    {option.sku.length > 0 && (
                                        <React.Fragment>
                                            <span className="colHead">sku:</span>
                                            <span className="colValue">{option.sku}</span>
                                        </React.Fragment>
                                    )}
                                    {typeof option.manufacturer === "object" && option.manufacturer !== null && (
                                        <React.Fragment>
                                            <span className="colHead">manufacturer:</span>
                                            <span className="colValue">
                                                #{option.manufacturer.id} {option.manufacturer.name}
                                            </span>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div>
                                    <span className="colHead">title:</span>
                                    <span className="colValue">
                                        {option.title}
                                    </span>
                                </div>
                                {option.brands.map((brand) => {
                                    const tracked = typeof option.settings[brand.id] === "object" && option.settings[brand.id].is_tracked ? isTracked : isNotTracked,
                                        archived = typeof option.settings[brand.id] === "object" && option.settings[brand.id].is_archived ? isArchived : isNotArchived,
                                        prices = typeof option.maps[brand.id] === "object" ? option.maps[brand.id] : {};
                                    let maps = [];
                                    Object.keys(prices).forEach((currency_id) => {
                                        if (prices[currency_id]) {
                                            maps.push({
                                                currency: Currencies.CodeByID(currency_id).toLowerCase(),
                                                value: prices[currency_id]
                                            })
                                        }
                                    });
                                    maps = maps.length ? (
                                        <React.Fragment>
                                            <span className="colHead">MAP:</span>
                                            {maps.map((price) =>
                                                <React.Fragment
                                                    key={`currency-${option.id}-${brand.id}-${price.currency}`}>
                                                    <span className={`currency-flag currency-flag-${price.currency}`}></span>
                                                    <span>{price.value}</span>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>) : null;

                                    // option.maps
                                    return <div key={`p${option.id}b${brand.id}`}>
                                        <span className="colHead">brand:</span>
                                        <span className="colValue">
                                            #{brand.id} {brand.name}
                                        </span>
                                        <span className="colHead">tracked:</span>
                                        <span className="colValue" data-testid="product-tracked">{tracked}</span>
                                        <span className="colHead">archived:</span>
                                        <span className="colValue" data-testid="product-archived">{archived}</span>
                                        {maps !== null && maps}
                                    </div>
                                })}
                            </div>
                        </div>
                    )
                }
                }
                useCache={false}
            />
            {this.state.showOptions && (
                <Container>
                    <Row>
                        <Col sm={1}>Options:</Col>
                        <Col sm={3}><Form.Check type="checkbox" checked={this.state.includeArchivedProducts} setting="archived-products" onChange={this.changeSettings} label="Include archived products" disabled={this.props.disabled} /></Col>
                        <Col sm={3}><Form.Check type="checkbox" checked={this.state.includeDisabledBrands} setting="disabled-brands" onChange={this.changeSettings} label="Include disabled brands" disabled={this.props.disabled} /></Col>
                    </Row>
                </Container>
            )}
        </React.Fragment>
    }
}

ProductSearch.propTypes = {
    /**  */
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    includeArchivedProducts: PropTypes.bool,
    includeDisabledBrands: PropTypes.bool,
    brands: PropTypes.array.isRequired,
    manufacturers: PropTypes.array.isRequired,
    skip: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool.isRequired
};

ProductSearch.defaultProps = {
    onChange: (values) => {
        console.log("Product has changed", values);
    },
    placeholder: "Search for a product...",
    brands: [],
    manufacturers: [],
    skip: [],
    disabled: false
};

export default ProductSearch;
