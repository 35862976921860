import React, { createRef } from "react";
import PropTypes from "prop-types";
import BrandService from "../services/BrandService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

/**
 * Typeahead component for searching for Brands
 * @component
 */
class BrandSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.ref = createRef();
    }

    handleSearch = (query) => {
        this.setState({
            isLoading: true,
        });
        BrandService.typeahead(query).then((options) => {
            if (options === null) {
                // this request was canceled due to a change in the typeahead component
                return;
            }
            const list = this.props.skip.length > 0
                ? options.filter((row) => this.props.skip.indexOf(row.id) === -1)
                : options;

            this.setState({
                isLoading: false,
                options: list,
            });
        });
    };

    clear = () => {
        this.ref.current.clear();
    }

    render() {
        const filterBy = () => true;

        return <AsyncTypeahead
            {...this.state}
            ref={this.ref}
            filterBy={filterBy}
            labelKey="name"
            id="brand-search"
            maxResults={10}
            minLength={2}
            onSearch={this.handleSearch}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            onFocus={this.props.onFocus}
            defaultSelected={this.props.defaultSelected}
            selected={this.props.selected}
            placeholder="Search for a brand..."
            renderMenuItemChildren={(option) => (
                <div
                    key={`m${option.id}`}
                    className={`brandActive${option.active} ${option.pdlt ? 'brandPDLT' : ''}`}
                >
                    <span className="colHead">id:</span>
                    <span className="colValue">
                        {option.id}
                    </span>
                    <span className="colHead">name:</span>
                    <span className="colValue">
                        {option.name}
                        {option.pdlt === 1 ? ' (PDLT)' : ''}
                    </span>
                    <span className="colHead">organization:</span>
                    <span className="colValue">
                        {option.organization}
                    </span>
                </div>
            )}
            useCache={false}
        />
    }
}

BrandSearch.propTypes = {
    /**  */
    onChange: PropTypes.func.isRequired,
    skip: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.object),
    onFocus: PropTypes.func,
};

BrandSearch.defaultProps = {
    onChange: (values) => {
        console.log("Brand has changed", values);
    },
    skip: [],
    disabled: false,
    selected: null,
    onFocus: () => {},
};

export default BrandSearch;
