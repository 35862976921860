import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import Brand from "../../services/BrandService";
import Toast from "../../services/Toast";
import { Breadcrumb, Button, Container } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import prompt from "../../components/Prompt";
import ProcessingButton from "../../components/ProcessingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCog, faDownload, faEnvelope, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import AuthService from "../../services/AuthService";


/**
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class List extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = createRef();
        this.state = {
            downloading: false,
            countries: ["United States", "Canada"],
            categories: []
        }
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.createNewBrand = this.createNewBrand.bind(this);
        this.allowedToCreateNewBrands = AuthService.isAdmin();
    }

    componentDidMount() {
        Brand.all().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of brands");
                return;
            }
            let countries = {};
            let categories = {};
            data.forEach((row) => {
                let list = typeof row['countries'] === 'string' ? row['countries'].split(', ') : [];
                list.forEach((element) => {
                    if (element.length) {
                        countries[element] = null;
                    }
                })
                row['categories'].forEach((category) => {
                    categories[category.name] = null;
                })
            })

            this.setState({ countries: Object.keys(countries), categories: Object.keys(categories).sort() }, () => {
                this.tableRef.current?.populate(data);
            })
        });
    }

    createNewBrand() {
        prompt("Create new Brand", "Create", "Cancel", "Brand name:", '').then((evt) => {
            const name = evt.value.trim();
            if (name.length) {
                Brand.new(name).then((id) => {
                    if (id === false) {
                        Toast.error("Something went wrong while creating the new organization");
                        return;
                    }
                    this.props.history.push("/brand/" + id + "#profile");
                    Toast.ok("Brand created successfully. DO NOT forget to associate it to an organization!", 5000);
                });
            }
        }).catch(() => { });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    render() {
        return (

            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        onClick={this.createNewBrand}
                        disabled={!this.allowedToCreateNewBrands}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{
                            marginRight: "5px"
                        }} />
                        New Brand
                    </Button>
                    <ProcessingButton
                        size="sm"
                        processing={this.state.downloading}
                        processingLabel="Downloading ..."
                        variant="success"
                        label="Download CSV"
                        icon={faDownload}
                        onClick={this.downloadSpreadsheet}
                    />
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Brand</Breadcrumb.Item>
                    <Breadcrumb.Item active>List</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="brand-list"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="created_at"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "organization",
                                label: "Organization",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "status",
                                label: "Status",
                                width: "100px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Active', value: '1' }, { label: 'Disabled', value: '0' }]
                            },
                            {
                                field: "manufacturers",
                                label: "Manufacturers",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "categories",
                                label: "Categories",
                                allowEmpty: true,
                                options: this.state.categories
                            },
                            {
                                field: "modules",
                                label: "Modules",
                                width: "150px",
                                sortable: true,
                                allowEmpty: true,
                                options: [
                                    { label: "MB - Merchant Badges", value: "MB" },
                                    { label: "MA - Marketplace Analytics", value: "MA" },
                                    { label: "CC - Content Check", value: "CC" },
                                    { label: "ME - Enforcement", value: "ME" },
                                    { label: "MV - Market Visibility", value: "MV" },
                                    { label: "PMP - Partner Management Portal", value: "PMP" },
                                    { label: "PRT - Product Review Tracking", value: "PRT" },
                                    { label: "SCR - Seller Central Reports", value: "SCR" },
                                    { label: "CPP - Competitor Intelligence", value: "CPP" },
                                    { label: "AMZ - Amazon Insights", value: "AMZ" }
                                ]
                            },
                            {
                                field: "countries",
                                label: "Countries",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.countries
                            },
                            {
                                field: "sales_status",
                                label: "Sales Status",
                                sortable: true,
                                allowEmpty: true,
                                options: [
                                    { label: "Unknown", value: "Unknown" },
                                    { label: "Demo - Build In Progress", value: "Demo - Build In Progress" },
                                    { label: "Demo - Ready", value: "Demo - Ready" },
                                    { label: "Demo - Brand Still Evaluating", value: "Demo - Brand Still Evaluating" },
                                    { label: "Demo - Status Unknown", value: "Demo - Status Unknown" },
                                    { label: "Demo - Did Not Convert", value: "Demo - Did Not Convert" },
                                    { label: "Trial - Free", value: "Trial - Free" },
                                    { label: "Trial - Free (Special Offer)", value: "Trial - Free (Special Offer)" },
                                    { label: "Trial - Paid", value: "Trial - Paid" },
                                    { label: "Trial - Did Not Convert", value: "Trial - Did Not Convert" },
                                    { label: "Paying/Active", value: "Paying/Active" },
                                    { label: "Delinquent", value: "Delinquent" },
                                    { label: "Canceled - Churned/Lost", value: "Canceled - Churned/Lost" },
                                    { label: "Products Moved", value: "Products Moved" },
                                    { label: "Mistake / Duplicate", value: "Mistake / Duplicate" },
                                    { label: "Paying/Active - Untracked Account", value: "Paying/Active - Untracked Account" },
                                    { label: "Used for Testing", value: "Used for Testing" },
                                    { label: "Demo - PDLT", value: "Demo - PDLT" },
                                    { label: "Disabled - PDLT", value: "Disabled - PDLT" },
                                ]
                            },
                            {
                                field: "constraints",
                                label: "W/B",
                                hint: "Whitelist/Blacklist",
                                width: "50px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: "Whitelist", value: "W" }, { label: "Blacklist", value: "B" }]
                            },
                            {
                                field: "products",
                                label: "# Products",
                                width: "100px",
                                sortable: true,
                                hint: "Product Catalog Count"
                            },
                            {
                                field: "smtp",
                                label: "SMTP",
                                width: "60px",
                                sortable: true
                            },
                            {
                                field: "created_at",
                                label: "Created",
                                width: "80px",
                                sortable: true,
                                searchable: true
                            }
                        ]
                    }
                    renderRows={{
                        className: (row) => row.status !== '1' ? "blocked" : ""
                    }}
                    renderColumns={{
                        id: (row) => <Link className="link" to={`/brand/${row.id}`}>{row.id}</Link>,
                        name: (row) => <Link className="link" to={`/brand/${row.id}`}>{row.name}</Link>,
                        organization: (row) => <Link className="link" to={`/organization/${row.org_id}`}>{row.organization}</Link>,
                        manufacturers: {
                            text: (row) => row.manufacturers.length ? row.manufacturers.map((r) => {
                                return r.name;
                            }).join('; ') : '',
                            csv: (row) => row.manufacturers.map((r) => {
                                return '#' + r.id + ' ' + r.name;
                            }).join('; '),
                            format: (row) => row.manufacturers.map((r) => {
                                return r.name;
                            }).join('; ')
                        },
                        categories: {
                            text: (row) => row.categories.length ? row.categories.map((r) => {
                                return r.name;
                            }).sort().join('; ') : '',
                            csv: (row) => row.categories.map((r) => {
                                return '#' + r.id + ' ' + r.name;
                            }).sort().join('; '),
                            format: (row) => row.categories.map((r) => {
                                return r.name;
                            }).sort().join('; ')
                        },
                        status: {
                            csv: (row) => row.status === '1' ? 'Active' : 'Disabled',
                            format: (row) => row.status === '1' ? (
                                <FontAwesomeIcon
                                    style={{
                                        color: "green",
                                    }}
                                    icon={faCheck}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />
                            ),
                            className: "text-center"
                        },
                        new_workflow_engine: {
                            csv: (row) => {
                                let content = [];
                                if (row.new_workflow_engine.indexOf('E') !== -1) {
                                    content.push('Engine');
                                }
                                if (row.new_workflow_engine.indexOf('N') !== -1) {
                                    content.push('Notification');
                                }
                                return content.join(', ');
                            },
                            format: (row) => {
                                return <React.Fragment>
                                    <FontAwesomeIcon icon={faCog} style={row.new_workflow_engine.indexOf('E') !== -1 ? { color: "green" } : { color: "silver" }} />&nbsp;
                                    <FontAwesomeIcon icon={faEnvelope} style={row.new_workflow_engine.indexOf('N') !== -1 ? { color: "green" } : { color: "silver" }} />
                                </React.Fragment>
                            },
                            className: "text-center"
                        },
                        constraints: {
                            csv: (row) => row.constraints.indexOf('B') !== -1 ? 'Blacklist' : (row.constraints.indexOf('W') !== -1 ? 'Whitelist' : ''),
                            className: "text-center bold"
                        },
                        smtp: {
                            csv: (row) => row.smtp ? 'Yes' : 'No',
                            format: (row) => row.smtp ? (
                                <FontAwesomeIcon
                                    style={{
                                        color: "green",
                                    }}
                                    icon={faCheck}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />
                            ),
                            className: "text-center"
                        }
                    }}
                />
            </Container>
        )
    }
}

export default withRouter(List);
